// App styling
// -----------------------
//
// 1.  Skeleton styling


// 1.  Skeleton styling
// -----------------------

.skeleton {
    &:before {
        display: inline-block;
        content: '';
        opacity: .1;
        background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgb(103, 104, 121) 30%, rgb(103, 104, 121) 70%, rgba(0, 0, 0, 0) 100%);
        width: 300%;
        height: 300%;
        margin-bottom: 20px;
        animation-duration: 1.2s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: shine;
        animation-timing-function: cubic-bezier(0, 0, 0.35, 1);
    }
}

@keyframes shine {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}